import React from 'react';
import { css } from '@emotion/core';

import NavLink from '../nav-link';
import Logo from '../logo';

const HeaderLogo = () => {
  const logoStyles = css`
    text-decoration: none;
    width: 120px;
    svg {
      display: block;
      max-width: 100%;
    }

    @media (min-width: 768px) {
      width: 180px;
    }
  `;
  return (
    <NavLink className={'site-logo'} url="/" css={logoStyles}>
      <Logo />
    </NavLink>
  );
};

export default HeaderLogo;
