import React from 'react';
import { css } from '@emotion/core';

function MenuBurger({ color, onClick }) {
  const burgerStyles = css`
    display: block;
    position: relative;
    z-index: 2;
    background: none;
    border: none;
    cursor: pointer;
    @media (min-width: 960px) {
      display: none;
    }
  `;
  return (
    <button css={burgerStyles} onClick={onClick}>
      <svg
        height="24"
        width="24"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g
          fill={color}
          stroke={color}
          strokeLinecap="square"
          strokeWidth="1"
          transform="translate(0.5 0.5)"
        >
          <line fill="none" x1="1" x2="23" y1="12" y2="12" />
          <line fill="none" stroke={color} x1="1" x2="23" y1="5" y2="5" />
          <line fill="none" stroke={color} x1="1" x2="23" y1="19" y2="19" />
        </g>
      </svg>
    </button>
  );
}

export default MenuBurger;
