import React from 'react';
import { Global, css } from '@emotion/core';

const GlobalStyle = () => {
  return (
    <Global
      styles={css`
        html {
          --color-text: #ffffff;
          --color-background: #000000;
          --color-navigation: #ffffff;
        }

        * {
          box-sizing: border-box;
          margin: 0;
        }
        html,
        body {
          margin: 0;
          height: -webkit-fill-available;
          min-height: 100vh;
          min-height: -webkit-fill-available;

          /* remove margin for the main div that Gatsby mounts into */
          > div {
            margin-top: 0;
          }
        }

        body {
          font-family: 'Univers Roman', sans-serif;
          font-size: 16px;
          line-height: 1.4;
          color: var(--color-text);
          background-color: var(--color-background);
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          min-height: 100vh;
          min-height: -webkit-fill-available;
          transition: background-color 700ms ease-in-out,
            color 700ms ease-in-out;
        }
        .tl-edges {
          overflow: hidden;
        }
        button {
          padding: 0px;

          &:hover,
          &:focus {
            outline: none;
          }
        }

        img {
          max-width: 100%;
        }
        .site-logo {
          svg {
            fill: var(--color-navigation);
          }
        }
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          color: var(--color-text);
        }

        p {
          color: var(--color-text);
        }
        strong {
          font-family: 'Univers Roman';
          font-weight: normal;
        }
        a {
          color: var(--color-text);
          text-decoration: none;
        }

        .current-page:after {
          width: 100%;
          left: 0;
        }

        @media (max-width: 768px) {
          .video-block {
            min-width: 100vw;
            margin-left: -16px;
            margin-right: -16px;
          }
        }
      `}
    />
  );
};

export default GlobalStyle;
