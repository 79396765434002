import React from 'react';
import { css } from '@emotion/core';

import HeaderLogo from './header_logo';
import HeaderMenu from './header_menu';

const Header = () => {
  const headerStyles = css`
    display: flex;
    flex-direction: row;
    position: absolute;
    align-items: center;
    justify-content: space-between;
    z-index: 50;
    width: 100%;
    padding: 0px 16px;

    @media (min-width: 768px) {
      padding: 0px 30px;
      flex-direction: row;
    }
  `;
  return (
    <header css={headerStyles}>
      <HeaderLogo />
      <HeaderMenu />
    </header>
  );
};

export default Header;
