import React, { useRef, useEffect, useState } from 'react';
import { css } from '@emotion/core';
import gsap from 'gsap';

const Preloader = () => {
  const [animation, setAnimation] = useState(null);
  const wrapperRef = useRef(null);
  const titleRef = useRef(null);
  const lineRef = useRef(null);

  useEffect(() => {
    const wrapper = wrapperRef.current;
    const titleText = titleRef.current.firstElementChild;
    const line = lineRef.current;

    const timeline = new gsap.timeline({
      paused: true,
    });
    setAnimation(
      timeline
        .set(titleText, { visibility: 'visible' })
        .to(line, {
          duration: 1,
          ease: 'power3.out',
          scaleX: 1,
          transformOrigin: 'center center',
        })
        .from(
          titleText,
          { duration: 1.4, ease: 'power3.inOut', yPercent: 100 },
          '-=0.9',
        )
        .to(
          wrapper,
          { duration: 1.2, ease: 'power4.inOut', yPercent: -100 },
          'hideWrapper',
        ),
    );
  }, []);
  useEffect(() => {
    if (animation !== null) {
      animation.play();
    }
  }, [animation]);

  return (
    <div
      ref={wrapperRef}
      css={css`
        width: 100vw;
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 9999;
        background-color: black;
        display: flex;
        justify-content: center;
        align-items: center;
      `}
    >
      <div>
        <h1
          css={css`
            font-size: 40px;
            font-family: 'Univers Bold Condensed';
            text-transform: uppercase;
            line-height: 1;
            overflow: hidden;
            color: white;

            @media (min-width: 560px) {
              font-size: 32px;
            }
            @media (min-width: 768px) {
              font-size: 8vw;
            }
            @media (min-width: 1200px) {
              font-size: 80px;
              letter-spacing: -0.03em;
            }
            @media (min-width: 1480px) {
              font-size: 112px;
            }
          `}
          ref={titleRef}
        >
          <span
            css={css`
              display: block;
              visibility: hidden;
            `}
          >
            Highsnobiety
          </span>
        </h1>
        <span
          ref={lineRef}
          css={css`
            width: 100%;
            height: 0.4vw;
            display: block;
            background-color: white;
            margin-top: 0.4vw;
            transform: scaleX(0);
            transform-origin: 'center center';
          `}
        ></span>
      </div>
    </div>
  );
};

export default Preloader;
