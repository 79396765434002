import React from 'react';

import '../fonts/fonts.css';
import Preloader from './preloader';
import SEO from './seo';
import Header from './header/header';

import GlobalStyle from '../theme/global-style';
import { useEffect, useState } from 'react';

const Layout = ({ children, location }) => {
  const [shouldPreload, setShouldPreload] = useState(true);
  useEffect(() => {
    checkSessionStorage();
  });

  const checkSessionStorage = () => {
    const sessionCookie = window.sessionStorage.getItem('isLoaded');
    if (sessionCookie) {
      setShouldPreload(false);
    } else {
      window.sessionStorage.setItem('isLoaded', 'true');
      setShouldPreload(true);
    }
  };

  return (
    <>
      <GlobalStyle />
      <SEO pathname={location.pathname} />
      {shouldPreload && <Preloader />}
      <Header pathname={location.pathname} />
      <main>{children}</main>
    </>
  );
};

export default Layout;
