import React, { useState, useEffect } from 'react';
import { css } from '@emotion/core';
import NavLink from '../nav-link';
import MenuBurger from './header_menu-burger';

const MenuDesktop = () => {
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    if (isActive) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'visible';
    }
    return () => {
      document.body.style.overflow = 'visible';
    };
  }, [isActive]);

  const menuStyles = css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 64px;
    @media (min-width: 960px) {
      height: 96px;
      width: 100%;
    }
  `;
  const navStyles = css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    opacity: 1;
    position: fixed;
    left: 0;
    top: 0;
    background: black;
    transform: ${isActive ? 'translateX(0%)' : 'translateX(100%)'};

    @media (min-width: 960px) {
      flex-direction: row;
      justify-content: flex-end;
      position: relative;
      height: auto;
      background: transparent;
      transform: none;
      display: flex;
    }
  `;

  return (
    <div css={menuStyles}>
      <MenuBurger
        onClick={() => {
          setIsActive(!isActive);
        }}
        color={isActive ? '#ffffff' : 'var(--color-navigation)'}
      />
      <nav css={navStyles}>
        <NavLink
          menuLink={true}
          url="/work/"
          onClick={() => setIsActive(false)}
        >
          Work
        </NavLink>
        <NavLink
          menuLink={true}
          url="/services/"
          onClick={() => setIsActive(false)}
        >
          Services
        </NavLink>
        <NavLink
          menuLink={true}
          url="/insights/"
          onClick={() => setIsActive(false)}
        >
          Insights
        </NavLink>
        <NavLink
          menuLink={true}
          url="/about/"
          onClick={() => setIsActive(false)}
        >
          About
        </NavLink>
        <NavLink
          menuLink={true}
          url="/contact/"
          onClick={() => setIsActive(false)}
        >
          Contact
        </NavLink>
      </nav>
    </div>
  );
};

export default MenuDesktop;
