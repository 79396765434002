import React from 'react';
import { css } from '@emotion/core';
import TransitionLink from 'gatsby-plugin-transition-link';
import gsap from 'gsap';

const NavLink = ({ children, url, className, onClick, menuLink }) => {
  const linkStyles = css(css`
    text-decoration: none;
    text-transform: uppercase;

    font-size: 16px;
    margin: 15px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    position: relative;
    padding-bottom: 2px;

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      height: 1px;
      background-color: var(--color-navigation);
      width: 0;
      left: 50%;
      transition: width 500ms, left 500ms;
    }

    &:hover:after {
      width: 100%;
      left: 0;
    }
    &.current-page:after {
      width: 100%;
      left: 0;
    }
    &.site-logo {
      border: none;
    }
    @media (min-width: 960px) {
      color: var(--color-navigation);
      margin-right: 24px;
      font-size: 16px;
      &:last-of-type {
        margin-right: 0;
      }
    }
    @media (min-width: 1440px) {
      font-size: 16px;
    }
  `);

  const exitAnimation = (exit, node) => {
    gsap.to(node, {
      duration: 1,
      ease: 'power3.inOut',
      autoAlpha: 0,
      y: 20,
    });
  };
  const entryAnimation = (entry, node) => {
    gsap.from(node, {
      duration: 1,
      ease: 'power3.inOut',
      autoAlpha: 0,
      y: 20,
    });
  };

  return (
    <TransitionLink
      css={menuLink ? linkStyles : null}
      to={url}
      activeClassName={menuLink ? 'current-page' : null}
      partiallyActive={true}
      className={className}
      onClick={onClick}
      exit={{
        trigger: ({ exit, node }) => exitAnimation(exit, node),
        length: 1,
      }}
      entry={{
        trigger: ({ entry, node }) => entryAnimation(entry, node),
        delay: 1,
      }}
    >
      {children}
    </TransitionLink>
  );
};

export default NavLink;
